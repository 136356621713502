import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import Form from './pandemicForm';
import Hero from './pandemicHero';

/**
 * PandemicResponse page Main component.
 * @component
 *
 * @returns {component} - <Main />
 */

const Main = () => {
  const {
    contentfulPandemicResponse: {
      copy: {
        childMarkdownRemark: { html },
      },
      formButton,
      formConfirmationCopy,
      formHeading,
      title,
    },
    contentfulAsset,
  } = useStaticQuery(graphql`
    query {
      contentfulPandemicResponse {
        copy {
          childMarkdownRemark {
            html
          }
        }
        formButton
        formConfirmationCopy
        formHeading
        title
      }
      contentfulAsset(title: { eq: "pandemic-response" }) {
        fluid(
          maxHeight: 318
          maxWidth: 600
          quality: 85
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.contactMain,
        main.pandemicMain
      )}
    >
      <div
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyBetween,
          base.w100,
          main.contactInner
        )}
      >
        <Hero content={html} title={title} bgImg={contentfulAsset.fluid} />
        <div className={cx(base.h100, main.contactDivider)} />
        <Form
          button={formButton}
          confirmation={formConfirmationCopy}
          heading={formHeading}
        />
      </div>
    </section>
  );
};

export default Main;
