import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CleanHTML from '../cleanHTML';

const sizing = {
  maxHeight: '291.5px',
  maxWidth: '550px',
};

const wrap = {
  ...sizing,
};

const image = {
  ...sizing,
};

/**
 * Pandemic page Hero component.
 * @component
 *
 * @param   {string} bgImg   - Background image
 * @param   {string} content - Raw html content
 * @param   {string} title   - Page title
 * @returns {component}      - <Hero content={string} title={string} />
 */

const Hero = ({ bgImg, content, title }) => (
  <div
    className={cx(
      base.flex,
      base.flexColumn,
      base.itemsCenter,
      base.justifyCenter,
      base.relative,
      base.w100,
      main.pandemicWrap
    )}
  >
    <div
      className={cx(
        base.absolute,
        base.absoluteFill,
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100
      )}
    >
      <Img
        fluid={bgImg}
        alt=""
        aria-hidden="true"
        loading="eager"
        style={wrap}
        imgStyle={image}
      />
    </div>
    <h2
      className={cx(
        base.mb3,
        base.tc,
        main.memberTitle,
        main.heading,
        theme.colorBlue
      )}
    >
      {title}
    </h2>
    <CleanHTML html={content} />
  </div>
);

Hero.propTypes = {
  bgImg: PropTypes.shape({}).isRequired,
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Hero;
